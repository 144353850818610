@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fffafa;
    background-image: url("assets/img/bg.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    font-family: "Playfair Display";
  }

  input[type="radio"] + label span {
    transition: background 0.2s, transform 0.2s;
  }

  input[type="radio"] + label span:hover,
  input[type="radio"] + label:hover span {
    transform: scale(1.2);
  }
  input[type="radio"] + label {
    color: #231414;
    transition: all ease 400ms;
  }
  input[type="radio"] + label:hover {
    color: #8f7577;
  }

  input[type="radio"]:checked + label span {
    background-color: #eab2b2;
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type="radio"]:checked + label {
    color: #eab2b2;
  }
}

@font-face {
  font-family: "Adelia";
  src: url("../public/fonts/adelia.ttf");
}

@media (max-width: 768px) {
  body {
    background-image: url("assets/img/bg-mobile.png");
  }
}
